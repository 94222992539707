import React, { useEffect, useState } from "react";
import "../app/globals.css";
import { useRouter } from "next/router";
import NotFoundPage from "./404";

const Screen = () => {
    const router = useRouter();
    const { screen } = router.query;
    const [ScreenComponent, setScreenComponent] = useState<any>(null);

    useEffect(() => {
        const loadScreenComponent = async () => {
            try {
                if (screen) {
                    // Dynamically import the screen component based on the screen parameter
                    const component = await import(`../app/screens/${screen}`);
                    setScreenComponent(() => component.default);
                }
            } catch (error) {
                console.error(error);
                // Handle the case when the screen component does not exist or fails to load
                setScreenComponent(() => null);
            }
        };

        loadScreenComponent();
    }, [screen]);
    if (!ScreenComponent) {
        // Render loading state while the screen component is being loaded or if it failed to load
        return (
            <div className="common-container" id="container1">
            Loading ... 
            </div>
    
        );
    }

    if (!ScreenComponent && screen) {
        // Render "Page not found" if the URL doesn't correspond to any file
        return <NotFoundPage />;
    }

    return (
        <div className="common-container" id="container1">
            <ScreenComponent />
        </div>
    );
};

export default Screen;
