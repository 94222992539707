import React from "react";

const NotFoundPage = () => {
    return (
        <div className="common-container" id="container1">
            <h1>Page not found</h1>
            <p>The requested page does not exist.</p>
        </div>
    );
};

export default NotFoundPage;
